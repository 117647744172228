import { useMutation, useQuery } from "@tanstack/react-query";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

import { ApiInstance } from "../clients/api";
import store from "../store";

export const useOfferings = ({ search, page = 1 }, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(["offerings", "search", search, page], () =>
    offeringApi.search({ search, page }),
    {
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useUserOfferings = () => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(["offerings", "user"], () => offeringApi.userOfferings());
};

export const useIssuerOfferings = (issuerId) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(["offerings", issuerId], () =>
    offeringApi.issuerOfferings(issuerId)
  );
};

/**
 * @typedef {Parameters<typeof useQuery>[2]} UseQueryOptions
 * @param {*} id
 * @param {UseQueryOptions} options
 * @returns
 */
export const useOffering = (id, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(["offerings", id], () => offeringApi.legacyFind(id), {
    enabled: !!id,
    ...options,
    onSuccess: (...args) => {
      const [offering] = args;
      store.dispatch({ type: "offering", payload: offering });
      options?.onSuccess?.(...args);
    },
  });
};

/**
 * @param {string} offeringId
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useOfferingSignatoryUpdate = (offeringId, options = {}) => {
  const api = useApi(OfferingClient);

  return useMutation(
    (data) => api.updateSignatory(offeringId, data.signatoryId),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useOfferingUpdate = (id, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useMutation(async (data) => offeringApi.update(id, data), options);
};

/**
 * @typedef {Parameters<typeof useQuery>[2]} UseQueryOptions
 * @param {string} id
 * @param {UseQueryOptions} options
 * @returns
 */
export const useOfferingFull = (id, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offerings", id, "full"],
    () => offeringApi.getFullById(id),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export const useOfferingSummary = (id) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(["offering", id, "summary"], () => offeringApi.summary(id));
};
/**
 * @typedef {Parameters<typeof useQuery>[2]} UseQueryOptions
 * @param {*} id
 * @param {UseQueryOptions} options
 * @returns
 */
export const useOfferingSettings = (id, options) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offering", id, "settings"],
    () => offeringApi.settings(id),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export const useOfferingBrokers = (id) => {
  const offeringApi = useApi(OfferingClient);
  return useQuery(["offeringBrokers", id], () => offeringApi.brokers(id));
};

export const addOfferingBroker = (offeringId, brokerId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.post(`/v2/offerings/${offeringId}/broker/${brokerId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const removeOfferingBroker = (offeringId, brokerId) => {
  return new Promise(function (resolve, reject) {
    ApiInstance.delete(`/v2/offerings/${offeringId}/broker/${brokerId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const useOfferingInvestments = (
  id,
  {
    searchPhrase = null,
    page = 1,
    current = null,
    status = null,
    capturable = false,
    kycStatus = null,
    funded = false,
    sortBy = undefined,
  } = {},
  options = {}
) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    [
      "offerings",
      id,
      "investments",
      { searchPhrase, page, current, status, capturable, kycStatus, funded, sortBy },
    ],
    () =>
      offeringApi.investments(id, {
        search: searchPhrase,
        page,
        current,
        capturable,
        status,
        kycStatus,
        funded,
        sortBy,
      }),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export const useOfferingTrauncheAvailableInvestments = (
  offeringId,
  options = {}
) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offerings", offeringId, "tranches", "available-investments"],
    () => offeringApi.getAvailableInvestments(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

export const useOfferingTraunches = (offeringId, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offerings", offeringId, "tranches"],
    () => offeringApi.getTraunches(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

export const useOfferingTranche = (id, offeringId, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useQuery(
    ["offerings", offeringId, "tranches", id],
    () => offeringApi.getTranche(id, offeringId),
    {
      enabled: !!(id || offeringId),
      ...options,
    }
  );
};

export const useOfferingInvestorExport = (offeringId, options = {}) => {
  const offeringApi = useApi(OfferingClient);

  return useMutation(async (data) => offeringApi.exportInvestors(offeringId, data), options);
}
